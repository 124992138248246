<template>
  <div class="remind">
    <div class="search-top flex-between">
      <div></div>
      <div class="search-but">
        <el-button @click="addRemind" class="color-vice" type="primary">
          新增
        </el-button>
      </div>
    </div>
    <el-table class="sss" v-loading="tableLoading" :data="tableData" border>
      <el-table-column prop="notice_date" label="提醒日期" />
      <el-table-column
        prop="notice_content"
        show-overflow-tooltip
        label="提醒内容"
      />
      <el-table-column prop="doctor_name" label="医生" />
      <el-table-column prop="created_time" label="添加日期" />
      <el-table-column fixed="right" label="操作" width="160">
        <template #default="scope">
          <el-button
            type="text"
            size="small"
            @click.prevent.stop="openRemind(scope.row)"
          >
            <i class="txIons modify"></i>
          </el-button>
          <el-button
            type="text"
            size="small"
            @click.prevent.stop="delRemind(scope.row)"
          >
            <i class="txIons del"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:currentPage="pageData.currentPage"
        v-model:page-size="pageData.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.totalSum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <BaseDialog
      title="日历提醒"
      :isshow="modRemind"
      @handleShow="
        (val) => {
          modRemind = val
        }
      "
      width="650px"
      height="auto"
    >
      <div class="schemeBox">
        <div class="userItem">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="84px"
            label-position="right"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="提醒日期" prop="notice_date">
              <el-date-picker
                v-model="ruleForm.notice_date"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="请选择提醒日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="提醒内容" prop="notice_content">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入提醒内容"
                v-model="ruleForm.notice_content"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="modRemind = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveRemind"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { ref, reactive, toRefs, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'remind',
  components: {
    BaseDialog,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      searchData: {
        patient_id: route.params.id,
      },
      ruleForm: {},
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      modRemind: false,
      tableLoading: false,
      saveLoading: false,
      rules: {
        notice_date: [
          { required: true, message: '请选择提醒日期', trigger: 'change' },
        ],
        notice_content: [
          { required: true, message: '请输入提醒内容', trigger: 'blur' },
        ],
      },
    })
    const ruleFormRef = ref(null)

    const initData = () => {
      handleSearch()
    }
    const handleSearch = () => {
      state.pageData.currentPage = 1
      getDataList()
    }
    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }
    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }
    const getDataList = async () => {
      state.searchData.current = state.pageData.currentPage
      state.searchData.size = state.pageData.pageSize
      state.tableLoading = true
      let res = await service.post(
        '/api/patient_notice/get_notice_list',
        state.searchData
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.record
        state.pageData.totalSum = res.data.count
      }
    }
    const addRemind = () => {
      state.modRemind = true
      state.ruleForm = {}
      nextTick(() => {
        ruleFormRef.value.clearValidate()
      })
    }
    const openRemind = (row) => {
      state.modRemind = true
      state.ruleForm = JSON.parse(JSON.stringify(row))
      nextTick(() => {
        ruleFormRef.value.clearValidate()
      })
    }
    const delRemind = (item) => {
      ElMessageBox.confirm('您确认要删除吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/patient_notice/delete_notice', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }

    const saveRemind = () => {
      ruleFormRef.value.validate(async (valid) => {
        if (valid) {
          let url = ''
          state.ruleForm.patient_id = route.params.id
          if (state.ruleForm.id) {
            url = '/api/patient_notice/edit_notice'
          } else {
            url = '/api/patient_notice/create_notice'
          }
          let res = await service.post(url, state.ruleForm)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
            state.modRemind = false
          }
        }
      })
    }

    return {
      ...toRefs(state),
      ruleFormRef,
      handleSizeChange,
      handleCurrentChange,
      addRemind,
      openRemind,
      delRemind,
      saveRemind,
      initData,
    }
  },
}
</script>

<style scoped lang="scss">
.search-top {
  margin-bottom: 12px;
}
.schemeBox {
  margin-top: 16px;
}
</style>
